html,
body {
  font-size: 16px;
  min-height: 100vh;
  height: 100%;
}

body {
  background: #FAF7F7;
  margin: 0;
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;
  letter-spacing: 0.05em;
  font-size: 14px;
  color: #414141;
}

#root {
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
