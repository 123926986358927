@import './variables.scss';
@import './mixins.scss';

*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}

*,
*:active,
*:hover {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
    overflow: visible !important;
    -ms-overflow-style: none;
}

html, body {
    height: 100%;
    overflow: hidden;
}

body {
    font-weight: 400;
    padding-top: $header-height;
    position: relative;
}

audio, video {
      outline: none;
}

p {
    margin-bottom: 20px;
}

small {
    font-size: 11px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    small {
        font-size: 12px;
    }
}

b, strong {
    font-weight: 500;
}

/*
 * Ordered Lists
 */


.clist {
    list-style: none;

    & > li {
        &:before {
            font-family: $font-icon-md;
            margin: 0 10px 0 -20px;
            vertical-align: middle;
        }
    }

    &.clist-angle > li:before {
        content: "\f2fb";
    }

    &.clist-check > li:before {
        content: "\f26b";
    }

    &.clist-star > li:before {
        content: "\f27d";
    }
}


#main {
    position: relative;
}

body {
    #content {
        padding: 30px 0;
    }
    #info-footer {
        border-radius: 3px;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0 30px;
        height: 60px;
        width: calc(100% - 60px);
        @include z-depth(2);
    }

    &.sw-toggled {
        #info-footer {
            @media (min-width: $screen-lg-min) {
                margin: 0 30px 0 #{$sidebar-left-width + 30px};
                width: calc(100% - #{$sidebar-left-width + 60px});
            }
        }

        #content {
            @media (min-width: $screen-lg-min) {
                padding: 30px 0 30px $sidebar-left-width;
            }

            & > .container {
                @media (min-width: $screen-lg-min) {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}

ul.tab-nav:not(.tn-vertical) {
    box-shadow: inset 0 -2px 0 0 #BEBEBE !important;
}

/* apply original text case */
.initial-case {
    text-transform: initial !important;
}

